<template>
  <div class="content relative content-wrapper w-full bg-gradient-to-b from-blue-25 to-yellow-25 overflow-hidden px-16 py-16">
    <section class="max-w-7xl mx-auto">
      <div class="grid grid-cols-2 gap-16">
        <div class="col-auto">
          <div class="space-y-12">
            <h1 class="text-3xl font-extrabold">
              Sikeres fizetés
            </h1>
            <div class="text-lg font-light">
              Kedves {{ name }} köszönjük a bizalmat.
            </div>
          </div>
        </div>
        <div class="col-auto"></div>
      </div>
    </section>
  </div>
</template>

<script>
import { useRouter } from "vue-router"

export default {
  data() {
    return {
      name: "",
      router: useRouter(),
    }
  },
  methods: {

  },
  created() {
    if (!this.$root.newOrder) {
      //this.router.push("/")
    }
  }
}
</script>
